export default {
    "common.cancel": "Anuluj",
    "common.delete": "Usuń",
    "common.duplicate": "Duplikuj",
    "common.title": "Tytuł",
    "common.upload": "Prześlij",
    "common.create": "Utwórz",
    "common.file": "Plik",
    "common.section": "Sekcja",
    "common.add": "Dodaj",
    "common.continue": "Kontynuuj",
    "common.confirm": "Potwierdź",
    "common.save": "Zapisz",
    "common.saved": "Zapisano",
    "common.yes": "Tak",
    "common.no": "Nie",
    "common.skip": "Pomiń",
    "common.ok": "OK",
    "common.success": "Sukces",
    "common.sign_out": "Wyloguj się",
    "common.answer": "Odpowiedź",
    "common.rule": "Reguła",

    "message.createAnswer.success": "Odpowiedź została pomyślnie zapisana!",
    "message.createAnswer.error.answer": "Nie udało się utworzyć odpowiedzi",
    "message.createAnswer.error.image": "Nie udało się utworzyć obrazu",
    "message.createAnswer.error.image.type": "Nieprawidłowy typ obrazu",
    "message.getAnswers.error.invalidInput":
        "Wprowadź maksymalny okres {{maxDays}} dni, aby filtrować odpowiedzi.",
    "message.getAnswers.error.answer": "Błąd podczas przetwarzania AnswerData",
    "message.getAnswers.error.image": "Błąd podczas przetwarzania obrazów",
    "message.formValidation.missingTitle": "Wypełnij pole Tytuł",
    "message.formValidation.missingEmail": "Wypełnij pole email",
    "message.formValidation.missingSectionTitle": "Wypełnij tytuł wszystkich sekcji",
    "message.formValidation.missingFieldTitle": "Wypełnij tytuły wszystkich pól",
    "message.formValidation.missingFieldType": "Wybierz typ dla wszystkich pól",
    "message.formValidation.missingFieldId": "Wprowadź Id dla wszystkich pól.",
    "message.formValidation.missingEquipmentMrk": "Wybierz MRK sprzętu w polu sprzętu",
    "message.formValidation.missingSection": "Formularz potrzebuje przynajmniej jednej sekcji, aby działać poprawnie",
    "message.formValidation.multipleCustomerField": "Formularz może mieć tylko jedno pole SAR Customer",
    "message.formValidation.multipleEquipmentInSection":
        "Formularz może mieć tylko jedno pole SAR Equipment na sekcję",
    "message.formValidation.missingCustomerField":
        "Aby utworzyć formularz z SAR Equipment, wprowadź pole typu SAR Customer przed tym",
    "message.formValidation.missingEquipmentField":
        "Aby utworzyć formularz z SAR Campaign/Ending Reason, wprowadź pole typu SAR Equipment przed tym",
    "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
        "SAR Customer musi być wprowadzony przed SAR Equipment",
    "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
        "SAR Customer musi być wprowadzony przed SAR Campaign",
    "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
        "SAR Customer musi być wprowadzony przed SAR End Reason",
    "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
        "SAR Equipment musi być wprowadzony przed SAR Campaign",
    "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
        "SAR Equipment musi być wprowadzony przed SAR End Reason",
    "message.formValidation.wrongEquipmentFieldPosition":
        "SAR Equipment musi być umieszczony przed SAR Campaign",
    "message.formValidation.failureValidatingRules": "Błąd walidacji reguł formularza.",
    "message.formValidation.missingTargetOptionsInRules":
        "Brak opcji pytań w regułach formularza.",
    "message.createForm.success": "Formularz zapisany pomyślnie!",
    "message.createForm.error": "Błąd zapisywania formularza. Spróbuj ponownie.",
    "message.updateForm.success": "Formularz zapisany pomyślnie.",
    "message.updateForm.error": "Błąd zapisywania formularza",
    "message.toggleFormEnable.success.enable": "Formularz włączony pomyślnie!",
    "message.toggleFormEnable.success.disable": "Formularz wyłączony pomyślnie!",
    "message.toggleFormEnable.error": "Błąd włączania/wyłączania formularza",
    "message.hasAnswers": "Formularz ma odpowiedzi.",
    "message.hasNoAnswers": "Formularz nie ma odpowiedzi.",
    "message.hasAnswers.error": "Błąd podczas sprawdzania, czy formularz ma odpowiedzi",
    "message.deleteForm.success": "Formularz usunięty pomyślnie.",
    "message.deleteForm.error": "Błąd usuwania formularza.",
    "message.duplicate.modal.title": "Jaką nazwę chcesz nadać swojej kopii?",
    "message.duplicateForm.success": "Formularz pomyślnie zduplikowany.",
    "message.duplicateForm.error": "Błąd duplikowania formularza.",
    "message.getMrk.error": "Błąd pobierania MRK",
    "message.getCulture.error": "Nieobsługiwany język",

    "messages.oops": "Ups",
    "messages.link_copied": "Link skopiowany",
    "messages.confirm_delete":
        "Czy na pewno chcesz usunąć {{formTitle}}? Ta operacja jest nieodwracalna.",
    "messages.confirm_delete_answers":
        "Ten formularz ma już odpowiedzi. Kontynuując, wszystkie odpowiedzi zostaną usunięte, a ta operacja jest nieodwracalna. Czy chcesz kontynuować?",
    "messages.confirm_title_duplicate": "Zostanie przeniesiony na górę listy z poniższą nazwą:",
    "messages.required_fields": "Wypełnij wszystkie wymagane pola",
    "messages.error_support":
        "Wystąpił problem podczas wykonywania tej operacji. Jeśli błąd się powtarza, skontaktuj się z naszym wsparciem.",
    "messages.error_field_id": "Sprawdź Id pola!",
    "messages.error_field_no_options": "Sprawdź opcje pól!",
    "messages.error_field_preference_no_options": "Sprawdź preferencje pola",
    "messages.error_qrbarcode_field_preference_no_options":
        "Nie możesz zapisać pola QR/Barcode bez wybrania przynajmniej jednego typu",
    "messages.error_field_dropdown.title": "Nie możesz zapisać rozwijanej listy bez opcji.",
    "messages.error_field_weight": "Sprawdź pola wagowe!",
    "messages.error_all_field_weight": "Uzupełnij wszystkie pola wagowe!",
    "messages.error_fields_repeated_id":
        "Id pola {{fieldIdA}} sekcji {{sectionIdA}} jest takie samo jak Id pola {{fieldIdB}} sekcji {{sectionIdB}}.",
    "messages.error_section_repeated_id": "Istnieją sekcje z tym samym Id!",
    "messages.error_section_name": "Sprawdź nazwę sekcji!",
    "messages.error_section_id": "Sprawdź Id sekcji!",
    "messages.error_section_repeated_name": "Istnieją sekcje z tą samą nazwą!",
    "messages.error_duplicate_section": "Nie można zduplikować sekcji bez pól. Utwórz nową sekcję zamiast tego.",
    "messages.error_mrk": "Nie znaleziono MRK.",
    "messages.error_multiphotos_preferences": "Sprawdź preferencje multiphotos!",
    "messages.error_invalid_preference": "Nieprawidłowe wartości preferencji",
    "messages.construction": "Ta sekcja jest w budowie, aby poprawić Twoje doświadczenie",
    "messages.languages_note":
        "Jeśli pozostawisz pole puste, będzie ono wyświetlane jako domyślny język dla użytkownika.",
    "messages.users_not_found": "Nie znaleziono użytkowników",
    "messages.confirm_rules": "Czy chcesz ustalić reguły dla tego formularza?",
    "messages.empty_rule": "Jeśli masz regułę warunkową, możesz ją utworzyć tutaj",
    "messages.empty_section": "Wybierz pole, aby dodać je do sekcji",
    "messages.saved": "Twój formularz został pomyślnie zapisany! Możesz teraz sprawdzić go w Moich formularzach.",
    "messages.sign_out": "Czy chcesz się wylogować?",
    "messages.cannot_change_form_status":
        "Nie możesz włączyć ani wyłączyć tego formularza, ponieważ jesteś współwłaścicielem.",
    "pages.my_forms": "Moje formularze",
    "pages.new_form": "Nowy formularz",
    "pages.feedback": "Opinie",
    "pages.builder": "Budowniczy",
    "pages.rules": "Reguły",
    "pages.languages": "Języki",
    "pages.users": "Użytkownicy",
    "pages.usersAndCoOwners": "Użytkownicy i współwłaściciele",
    "my_forms.id": "ID",
    "my_forms.form_code": "Kod formularza",
    "my_forms.creation_date": "Data utworzenia",
    "my_forms.updated_date": "Ostatnie zmiany",
    "my_forms.enable": "Włącz",
    "my_forms.owner": "Właściciel",
    "my_forms.actions": "Akcje",
    "actions.form": "Edytuj formularz",
    "actions.rules": "Edytuj zasady",
    "actions.users": "Edytuj użytkowników",
    "actions.languages": "Edytuj języki",
    "actions.power_bi": "Link do Power BI",
    "actions.export": "Eksportuj formularz",
    "actions.delete": "Usuń formularz",
    "actions.duplicate": "Duplikuj formularz",
    "new_form.import": "Importuj nowy formularz",
    "new_form.creation_language": "Język tworzenia",
    "languages.portuguese": "Portugalski",
    "languages.english": "Angielski",
    "languages.spanish": "Hiszpański",
    "languages.german": "Niemiecki",
    "languages.mandarin": "Mandaryński",
    "languages.french": "Francuski",
    "languages.turkish": "Turecki",
    "languages.czech": "Czeski",
    "languages.swedish": "Szwedzki",
    "languages.polish": "Polski",
    "languages.slovenian": "Słoweński",
    "languages.kosovo_albanian": "Albański",
    "fields.text": "Krótki tekst",
    "fields.textarea": "Długi tekst",
    "fields.number": "Liczba",
    "fields.datetime": "Data i godzina",
    "fields.photo": "Zdjęcie",
    "fields.dropdown": "Lista rozwijana",
    "fields.single_option": "Pojedyncza opcja",
    "fields.customer": "Klient",
    "fields.equipment": "Sprzęt",
    "fields.campaign": "Kampania",
    "fields.end_reason": "Powód zakończenia",
    "fields.qr_code": "Kod QR/Kod kreskowy",
    "fields.signature_qr": "Podpis QR",
    "fields.boolean": "Pojedyncza opcja",
    "fields.dropdown-campaign": "Kampania",
    "fields.dropdown-campaign-end-reason": "Powód zakończenia",
    "fields.dropdown-customer": "Klient",
    "fields.dropdown-equipment": "Sprzęt",
    "fields.pictureFile": "Zdjęcie",
    "fields.qrbarcode": "Kod QR/Kod kreskowy",
    "fields.signature-qrcode": "Podpis QR",
    "fields.check_list": "Checklist",
    "constructor.fields": "Pola",
    "constructor.filter": "Filtr",
    "constructor.by.equipment": "Filtruj według sprzętu",
    "constructor.by.customer": "Filtruj według klienta",
    "constructor.basic": "Podstawowy",
    "preferences.checklistValue": "Maksymalna liczba zaznaczonych opcji",
    "constructor.rhim": "Integracja RHIM",
    "constructor.section_title": "Tytuł sekcji",
    "constructor.unsaves_changes": "Nie zapisane zmiany",
    "constructor.mrk": "MRK sprzętu",
    "constructor.preferences": "Preferencje",
    "preferences.placeholder": "Wybierz pole, aby edytować jego preferencje",
    "preferences.required": "Wymagane",
    "preferences.score": "Wynik",
    "preferences.options": "Opcje",
    "preferences.automatic": "Automatyczne",
    "preferences.automatic_id": "Automatyczne Id",
    "preferences.field_id": "Id pola",
    "preferences.field_weight": "Waga pola",
    "preferences.section_weight": "Waga sekcji",
    "preferences.section_id": "Id sekcji",
    "preferences.options_placeholder": "Wpisz elementy, oddzielając je enterem",
    "preferences.mrk_placeholder": "Wybierz MRK",
    "preferences.max": "Maksimum",
    "preferences.min": "Minimum",
    "preferences.formDescription": "Użyj jako opis formularza",
    "preferences.error_empty_qrcode": "Wybierz przynajmniej jedną opcję",
    "preferences.typeQrOrBarCode": "Typ",
    "preferences.IntegerOrFloat": "Typ",
    "preferences.decimalPlacesMin": "Minimalna liczba miejsc po przecinku",
    "preferences.DecimalPlaces": "Miejsca po przecinku",
    "add_options.option": "Opcja",
    "add_options.weight": "Waga",
    "add_options.response": "Odpowiedź",
    "add_options.add_na": "Dodaj N/D",
    "construction.go_back": "Wróć",
    "languages.notes": "Notatki",
    "users.email": "E-mail użytkowników",
    "coOwners.email": "E-mail współwłaścicieli",
    "users.multiple_association": "Wielokrotne powiązanie (wypełnij listą e-maili oddzieloną średnikami)",
    "users.finish": "Zakończ formularz",

    "globalization.title": "Napisz tytuł formularza w",
    "globalization.section": "Napisz tytuł sekcji w",
    "globalization.field": "Napisz nazwę pola w",
    "globalization.field.description": "Napisz opis pola w",
    "globalization.option": "Napisz wartość opcji w",

    "rules.if": "Jeśli",
    "rules.status": "Status",
    "rules.response": "Odpowiedź",
    "rules.action": "Akcja",
    "rules.question": "Pytanie",
    "rules.options": "Opcje",

    "option.is": "Jest",
    "option.isn't": "Nie jest",
    "option.hide.section": "Ukryj sekcję",
    "option.hide.field": "Ukryj pole",
    "option.show.section": "Pokaż sekcję",
    "option.show.field": "Pokaż pole",
    "option.filter": "Filtr",
    "option.required": "Wymagane",

    "pagination.view": "Wyświetlanie {{from}}-{{to}} z {{count}}",
    "pagination.rows_per_page": "Wiersze na stronę:",
    "messages.error_form_access_denied": "Nie masz dostępu do tego formularza. Proszę poszukaj formularza, w którym jesteś właścicielem lub współwłaścicielem",
    "messages.page_not_found": "Strona nie znaleziona",
    "messages.text_page_not_found": "Przepraszamy, strona, do której próbujesz uzyskać dostęp, nie istnieje.",
    "messages.go_back_my_forms": "Wróć do Moich formularzy",
    "messages.access_denied": "Dostęp zabroniony",
    "messages.text_access_denied": "Nie masz dostępu do tego formularza. Proszę poszukaj innego formularza, w którym jesteś autorem lub współautorem.",
    "messages.form_not_found": "Formularz nie znaleziony",
    "messages.text_form_not_found": "Przepraszamy, formularz, do którego próbujesz uzyskać dostęp, nie istnieje. Proszę poszukaj innego formularza lub utwórz nowy.",
    "preferences.description": "Opis",
    "messages.fieldsInformDescriptionPreference": "Wybrałeś już {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} pola",
    "messages.error_single_option_no_options": "Nie możesz zapisać pola z pojedynczą opcją bez opcji.",
    "messages.max_filled_as_form_description": "Aby wybrać to pole, odznacz inne pole",

    "preferences.option_integer": "Całkowita",
    "preferences.option_decimal": "Dziesiętna",
    "messages.tooltip_decimal_minimum_alert": "Ze względu na 2 miejsca po przecinku, minimalny limit znaków dla pól typu dziesiętnego wynosi 3.",
    "message.form_last_update": "Ostatnia aktualizacja",
    "message.form_last_update_by": "Przez"
}

