export default {
    "common.cancel": "Anulo",
    "common.delete": "Fshi",
    "common.duplicate": "Kopjo",
    "common.title": "Titulli",
    "common.upload": "Ngarko",
    "common.create": "Krijo",
    "common.file": "Skeda",
    "common.section": "Sekcioni",
    "common.add": "Shto",
    "common.continue": "Vazhdo",
    "common.confirm": "Konfirmo",
    "common.save": "Ruaj",
    "common.saved": "Ruajtur",
    "common.yes": "Po",
    "common.no": "Jo",
    "common.skip": "Shko përpara",
    "common.ok": "Ok",
    "common.success": "Sukses",
    "common.sign_out": "Dalje",
    "common.answer": "Përgjigje",
    "common.rule": "Rregull",
  
    "message.createAnswer.success": "Përgjigja u ruajt me sukses!",
    "message.createAnswer.error.answer": "Dështoi krijimi i përgjigjes",
    "message.createAnswer.error.image": "Dështoi krijimi i imazhit",
    "message.createAnswer.error.image.type": "Lloji i imazhit është i pavlefshëm",
    "message.getAnswers.error.invalidInput": "Ju lutem vendosni një periudhë maksimale prej {{maxDays}} ditësh për të filtruar përgjigjet.",
    "message.getAnswers.error.answer": "Dështoi në analizimin e të dhënave të përgjigjes",
    "message.getAnswers.error.image": "Dështoi në analizimin e imazheve",
    "message.formValidation.missingTitle": "Ju lutem plotësoni fushën e Titullit",
    "message.formValidation.missingEmail": "Ju lutem plotësoni fushën e emailit",
    "message.formValidation.missingSectionTitle": "Ju lutem plotësoni titujt e të gjitha seksioneve",
    "message.formValidation.missingFieldTitle": "Ju lutem plotësoni të gjitha titujt e fushave",
    "message.formValidation.missingFieldType": "Ju lutem zgjidhni llojin për të gjitha fushat",
    "message.formValidation.missingFieldId": "Ju lutem vendosni një ID për të gjitha fushat.",
    "message.formValidation.missingEquipmentMrk": "Ju lutem zgjidhni një MRK të pajisjeve në fushën e pajisjeve",
    "message.formValidation.missingSection": "Një formular kërkon të paktën një seksion për të funksionuar siç duhet",
    "message.formValidation.multipleCustomerField": "Formulari mund të ketë vetëm një fushë SAR Customer",
    "message.formValidation.multipleEquipmentInSection": "Formulari mund të ketë vetëm një fushë SAR Equipment për seksion",
    "message.formValidation.missingCustomerField": "Për të krijuar një formular me SAR Equipment, ju lutem vendosni një fushë të tipit SAR Customer para",
    "message.formValidation.missingEquipmentField": "Për të krijuar një formular me SAR Campaign/Ending Reason, ju lutem vendosni një fushë të tipit SAR Equipment para",
    "message.formValidation.wrongCustomerFieldPositionAfterEquipment": "SAR Customer duhet të vendoset para SAR Equipment",
    "message.formValidation.wrongCustomerFieldPositionAfterCampaign": "SAR Customer duhet të vendoset para një SAR Campaign",
    "message.formValidation.wrongCustomerFieldPositionAfterEndingReason": "SAR Customer duhet të vendoset para një SAR End Reason",
    "message.formValidation.wrongEquipmentFieldPositionAfterCampaign": "SAR Equipment duhet të vendoset para SAR Campaign",
    "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason": "SAR Equipment duhet të vendoset para SAR End Reason",
    "message.formValidation.wrongEquipmentFieldPosition": "SAR Equipment duhet të vendoset para SAR Campaign",
    "message.formValidation.failureValidatingRules": "Dështoi në verifikimin e rregullave të formularit.",
    "message.formValidation.missingTargetOptionsInRules": "Opsionet e pyetjeve mungojnë në rregullat e formularit.",
    "message.createForm.success": "Formulari u ruajt me sukses!",
    "message.createForm.error": "Dështoi në ruajtjen e formularit. Provoni përsëri.",
    "message.updateForm.success": "Formulari u ruajt me sukses.",
    "message.updateForm.error": "Gabim në ruajtjen e formularit",
    "message.toggleFormEnable.success.enable": "Formulari u aktivizua me sukses!",
    "message.toggleFormEnable.success.disable": "Formulari u çaktivizua me sukses!",
    "message.toggleFormEnable.error": "Gabim në aktivizimin/çaktivizimin e formularit",
    "message.hasAnswers": "Formulari ka përgjigje.",
    "message.hasNoAnswers": "Formulari nuk ka përgjigje.",
    "message.hasAnswers.error": "Gabim në kontrollimin nëse formulari ka përgjigje",
    "message.deleteForm.success": "Formulari u fshi me sukses.",
    "message.deleteForm.error": "Gabim në fshirjen e formularit.",
    "message.duplicate.modal.title": "Me cilin emër dëshironi që kopja juaj të ruhet?",
    "message.duplicateForm.success": "Formulari u kopjua me sukses.",
    "message.duplicateForm.error": "Gabim në kopjimin e formularit.",
    "message.getMrk.error": "Gabim në marrjen e MRK",
    "message.getCulture.error": "Gjuha e papërshtatshme",
    
    "messages.oops": "Oups",
    "messages.link_copied": "Linku u kopjua",
    "messages.confirm_delete": "Jeni të sigurt që dëshironi të fshini {{formTitle}}? Ky veprim nuk mund të anulohet.",
    "messages.confirm_delete_answers": "Ky formular ka përgjigje tashmë. Duke vazhduar, të gjitha përgjigjet do të fshihen dhe ky veprim nuk mund të anulohet. Dëshironi të vazhdoni?",
    "messages.confirm_title_duplicate": "Do të dërgohet në krye të listës me emrin e mëposhtëm:",
    "messages.required_fields": "Ju lutem plotësoni të gjitha fushat e kërkuara",
    "messages.error_support": "Një problem ndodhi gjatë kryerjes së këtij veprimi. Nëse gabimi vazhdon, ju lutem kontaktoni mbështetjen tonë.",
    "messages.error_field_id": "Ju lutem kontrolloni ID-në e fushës!",
    "messages.error_field_no_options": "Ju lutem kontrolloni opsionet e fushave!",
    "messages.error_field_preference_no_options": "Ju lutem kontrolloni preferencat e fushës",
    "messages.error_qrbarcode_field_preference_no_options": "Nuk mund të ruani një fushë QR/Barcode pa zgjedhur të paktën një lloj",
    "messages.error_field_dropdown.title": "Nuk mund të ruani një dropdown pa opsione.",
    "messages.error_field_weight": "Ju lutem kontrolloni fushat e peshës!",
    "messages.error_all_field_weight": "Ju lutem plotësoni të gjitha fushat e peshës!",
    "messages.error_fields_repeated_id": "ID e fushës {{fieldIdA}} e seksionit {{sectionIdA}} është e njëjtë si ID e fushës {{fieldIdB}} e seksionit {{sectionIdB}}.",
    "messages.error_section_repeated_id": "Ka seksione me të njëjtin ID!",
    "messages.error_section_name": "Ju lutem kontrolloni emrin e seksionit!",
    "messages.error_section_id": "Ju lutem kontrolloni ID-në e seksionit!",
    "messages.error_section_repeated_name": "Ka seksione me të njëjtin emër!",
    "messages.error_duplicate_section": "Cannot duplicate a section with no fields. Create a New Section instead.",
    "messages.error_mrk": "Nuk u gjet MRK.",
    "messages.error_multiphotos_preferences": "Ju lutem kontrolloni preferencat e multiphotos!",
    "messages.error_invalid_preference": "Vlera të pavlefshme të preferencës",
    "messages.construction": "Ky seksion është në ndërtim për të përmirësuar përvojën tuaj",
    "messages.languages_note": "Nëse lini një fushë të zbrazët, ajo do të shfaqet si gjuha e parazgjedhur për përdoruesin.",
    "messages.users_not_found": "Nuk u gjetën përdorues",
    "messages.confirm_rules": "Dëshironi të vendosni rregulla për këtë formular?",
    "messages.empty_rule": "Nëse keni një rregull kushtor, mund të krijohet këtu",
    "messages.empty_section": "Zgjidhni një fushë për ta shtuar brenda seksionit",
    "messages.saved": "Formulari juaj është ruajtur",
    "messages.sign_out": "A dëshironi të lidhni nga sistemi?",
    "messages.cannot_change_form_status": "Nuk mund të aktivizoni ose deaktivizoni këtë formular, pasi jeni bashkë-pronar.",
    "pages.my_forms": "Formularët E Mi",
    "pages.new_form": "Formular i Ri",
    "pages.feedback": "Përgjigje",
    "pages.builder": "Ndërtuesi",
    "pages.rules": "Rregulla",
    "pages.languages": "Gjuhët",
    "pages.users": "Përdoruesit",
    "pages.usersAndCoOwners": "Përdoruesit dhe Bashkë-pronarët",
    "my_forms.id": "ID",
    "my_forms.form_code": "Kodi i Formularit",
    "my_forms.creation_date": "Data e Krijimit",
    "my_forms.updated_date": "Data e Ndryshimeve",
    "my_forms.enable": "Aktivizo",
    "my_forms.owner": "Pronar",
    "my_forms.actions": "Veprimet",
    "actions.form": "Redakto Formularin",
    "actions.rules": "Redakto Rregullat",
    "actions.users": "Redakto Përdoruesit",
    "actions.languages": "Redakto Gjuhët",
    "actions.power_bi": "Lidhja e Power BI",
    "actions.export": "Eksporto Formularin",
    "actions.delete": "Fshi Formularin",
    "actions.duplicate": "Dublikoni Formularin",
    "new_form.import": "Importo Formular të Ri",
    "new_form.creation_language": "Gjuha e Krijimit",
    "languages.portuguese": "Portugalisht",
    "languages.english": "Anglisht",
    "languages.spanish": "Spanjisht",
    "languages.german": "Gjermanisht",
    "languages.mandarin": "Mandarin",
    "languages.french": "Frëngjisht",
    "languages.turkish": "Turqisht",
    "languages.czech": "Çekisht",
    "languages.polish": "Polonisht",
    "languages.slovenian": "Sllovenisht",
    "languages.swedish": "Suedisht",
    "languages.kosovo_albanian": "Shqip (Kosovë)",
    "fields.text": "Tekst i Shkurtër",
    "fields.textarea": "Tekst i Gjatë",
    "fields.number": "Numërik",
    "fields.datetime": "Data dhe Ora",
    "fields.photo": "Foto",
    "fields.dropdown": "Dropdown",
    "fields.single_option": "Opsion i Vetëm",
    "fields.customer": "Klient",
    "fields.equipment": "Pajisje",
    "fields.campaign": "Fushatë",
    "fields.end_reason": "Arsyeja e Mbylljes",
    "fields.qr_code": "Kodi QR/Bar",
    "fields.signature_qr": "QR i Nënshkrimit",
    "fields.boolean": "Opsion i Vetëm",
    "fields.dropdown-campaign": "Fushatë",
    "fields.dropdown-campaign-end-reason": "Arsyeja e Mbylljes",
    "fields.dropdown-customer": "Klient",
    "fields.dropdown-equipment": "Pajisje",
    "fields.pictureFile": "Foto",
    "fields.qrbarcode": "Kodi QR/Bar",
    "fields.signature-qrcode": "QR i Nënshkrimit",
    "fields.check_list": "Checklist",
    "constructor.fields": "Fushat",
    "constructor.filter": "Filtër",
    "constructor.by.equipment": "Filtro sipas Pajisjes",
    "constructor.by.customer": "Filtro sipas Klientit",
    "constructor.basic": "Baza",
    "preferences.checklistValue": "Maksimumi i opsioneve të markuara",
    "constructor.rhim": "Integrimi RHIM",
    "constructor.section_title": "Titulli i Seksionit",
    "constructor.unsaves_changes": "Ndryshimet e Pa Ruajtura",
    "constructor.mrk": "MRK i Pajisjes",
    "constructor.preferences": "Preferencat",
    "preferences.placeholder": "Zgjidh një fushë për të redaktuar preferencat e saj",
    "preferences.required": "E Kërkuar",
    "preferences.score": "Pikët",
    "preferences.options": "Opsionet",
    "preferences.automatic": "Automatik",
    "preferences.automatic_id": "ID Automatik",
    "preferences.field_id": "ID e Fushës",
    "preferences.field_weight": "Pesha e Fushës",
    "preferences.section_weight": "Pesha e Seksionit",
    "preferences.section_id": "ID e Seksionit",
    "preferences.options_placeholder": "Shkruaj artikuj duke i ndarë me enter",
    "preferences.mrk_placeholder": "Zgjidh një MRK",
    "preferences.max": "Maksimumi",
    "preferences.min": "Minimumi",
    "preferences.formDescription": "Përdor si përshkrim të formularit",
    "preferences.error_empty_qrcode": "Zgjidh të paktën një opsion",
    "preferences.typeQrOrBarCode": "Lloji",
    "preferences.IntegerOrFloat": "Lloji",
    "preferences.decimalPlacesMin": "Vendosjet Decimale Min",
    "preferences.DecimalPlaces": "Vendosjet Decimale",
    "add_options.option": "Opsioni",
    "add_options.weight": "Pesha",
    "add_options.response": "Përgjigja",
    "add_options.add_na": "Shto N/A",
    "construction.go_back": "Kthehu Mbrapa",
    "languages.notes": "Shënime",
    "users.email": "E-mail i Përdoruesve",
    "coOwners.email": "E-mail i Bashkë-pronarëve",
    "users.multiple_association": "Asociimi shumëfishtë (mbushni me një listë e-mailesh të ndara me pikë dhe presje)",
    "users.finish": "Përfundo formularin",
    "globalization.title": "Shkruaj titullin e formularit në",
    "globalization.section": "Shkruaj titullin e seksionit në",
    "globalization.field": "Shkruaj emrin e fushës në",
    "globalization.field.description": "Shkruaj përshkrimin e fushës në",
    "globalization.option": "Shkruaj vlerën e opsionit në",
    "rules.if": "Nëse",
    "rules.status": "Statusi",
    "rules.response": "Përgjigja",
    "rules.action": "Veprimi",
    "rules.question": "Pyetja",
    "rules.options": "Opsionet",
    "option.is": "Është",
    "option.isn't": "Nuk Është",
    "option.hide.section": "Fsheh Seksionin",
    "option.hide.field": "Fsheh Fushën",
    "option.show.section": "Shfaq Seksionin",
    "option.show.field": "Shfaq Fushën",
    "option.filter": "Filtër",
    "option.required": "E Kërkuar",
    "pagination.view": "Duke parë {{from}}-{{to}} nga {{count}}",
    "pagination.rows_per_page": "Rreshta për faqe:",
    "messages.error_form_access_denied": "Nuk keni akses në këtë formular. Ju lutem kërkoni një formular ku jeni pronar ose bashkë-pronar",
    "messages.page_not_found": "Faqja nuk u gjet",
    "messages.text_page_not_found": "Na vjen keq, faqja që po përpiqeni të qaseni nuk ekziston.",
    "messages.go_back_my_forms": "Kthehu në Formularët E Mi",
    "messages.access_denied": "Aksesi i refuzuar",
    "messages.text_access_denied": "Nuk keni akses në këtë formular. Ju lutem, kërkoni një tjetër formular në të cilin jeni autor ose bashkë-autor.",
    "messages.form_not_found": "Formulari nuk u gjet",
    "messages.text_form_not_found": "Na vjen keq, formulari që po përpiqeni të qaseni nuk ekziston. Ju lutem, kërkoni një tjetër formular ose krijoni një të ri.",
    "preferences.description": "Përshkrimi",
    "messages.fieldsInformDescriptionPreference": "Keni selektuar tashmë {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fushat",
    "messages.error_single_option_no_options": "Nuk mund të ruani një fushë me opsion të vetëm pa opsione.",
    "messages.max_filled_as_form_description": "Për të selektuar këtë fushë, lutem çaktivizoni një tjetër fushë",
    "preferences.option_integer": "I tërë",
    "preferences.option_decimal": "Decimal",
    "messages.tooltip_decimal_minimum_alert": "Për shkak të 2 vendosjeve decimale, kufiri minimal i karaktereve për fushat e tipit decimal është 3.",
    "message.form_last_update": "Përditësimi i fundit",
    "message.form_last_update_by": "Nga"
};