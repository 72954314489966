export default {
    "common.cancel": "Avbryt",
    "common.delete": "Radera",
    "common.duplicate": "Duplicera",
    "common.title": "Titel",
    "common.upload": "Ladda upp",
    "common.create": "Skapa",
    "common.file": "Fil",
    "common.section": "Sektion",
    "common.add": "Lägg till",
    "common.continue": "Fortsätt",
    "common.confirm": "Bekräfta",
    "common.save": "Spara",
    "common.saved": "Sparad",
    "common.yes": "Ja",
    "common.no": "Nej",
    "common.skip": "Hoppa över",
    "common.ok": "Ok",
    "common.success": "Framgång",
    "common.sign_out": "Logga ut",
    "common.answer": "Svar",
    "common.rule": "Regel",

    "message.createAnswer.success": "Svaret sparades framgångsrikt!",
    "message.createAnswer.error.answer": "Misslyckades med att skapa svar",
    "message.createAnswer.error.image": "Misslyckades med att skapa bild",
    "message.createAnswer.error.image.type": "Ogiltig bildtyp",
    "message.getAnswers.error.invalidInput":
        "Ange en maximal period på {{maxDays}} dagar för att filtrera svaren.",
    "message.getAnswers.error.answer": "Misslyckades med att analysera svarData",
    "message.getAnswers.error.image": "Misslyckades med att analysera bilder",
    "message.formValidation.missingTitle": "Vänligen fyll i titelrutan",
    "message.formValidation.missingEmail": "Vänligen fyll i e-postfältet",
    "message.formValidation.missingSectionTitle": "Vänligen fyll i titeln för alla sektioner",
    "message.formValidation.missingFieldTitle": "Vänligen fyll i alla fälttitlar",
    "message.formValidation.missingFieldType": "Vänligen välj typ för alla fält",
    "message.formValidation.missingFieldId": "Vänligen sätt ett Id för alla fält.",
    "message.formValidation.missingEquipmentMrk": "Vänligen välj en utrustning MRK i utrustningsfältet",
    "message.formValidation.missingSection": "Ett formulär behöver minst en sektion för att fungera korrekt",
    "message.formValidation.multipleCustomerField": "Formuläret kan bara ha ett SAR kundfält",
    "message.formValidation.multipleEquipmentInSection":
        "Formuläret kan bara ha ett SAR utrustningsfält per sektion",
    "message.formValidation.missingCustomerField":
        "För att skapa ett formulär med SAR Utrustning, vänligen lägg till ett fält av typen SAR Kund före",
    "message.formValidation.missingEquipmentField":
        "För att skapa ett formulär med SAR Kampanj/Slutskäl, vänligen lägg till ett fält av typen SAR Utrustning före",
    "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
        "SAR Kund måste läggas in före SAR Utrustning",
    "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
        "SAR Kund måste läggas in före en SAR Kampanj",
    "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
        "SAR Kund måste läggas in före en SAR Slutskäl",
    "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
        "SAR Utrustning måste läggas in före SAR Kampanj",
    "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
        "SAR Utrustning måste läggas in före SAR Slutskäl",
    "message.formValidation.wrongEquipmentFieldPosition":
        "SAR Utrustning måste placeras före SAR Kampanj",
    "message.formValidation.failureValidatingRules": "Misslyckades med att validera formulärregler.",
    "message.formValidation.missingTargetOptionsInRules":
        "Frågealternativ saknas i formulärregler.",
    "message.createForm.success": "Formuläret sparades framgångsrikt!",
    "message.createForm.error": "Misslyckades med att spara formuläret. Försök igen.",
    "message.updateForm.success": "Formuläret sparades framgångsrikt.",
    "message.updateForm.error": "Fel vid sparande av formuläret",
    "message.toggleFormEnable.success.enable": "Formulär aktiverat framgångsrikt!",
    "message.toggleFormEnable.success.disable": "Formulär inaktiverat framgångsrikt!",
    "message.toggleFormEnable.error": "Fel vid aktivering/inaktivering av formuläret",
    "message.hasAnswers": "Formuläret har svar.",
    "message.hasNoAnswers": "Formuläret har inga svar.",
    "message.hasAnswers.error": "Fel vid kontroll av om formuläret har svar",
    "message.deleteForm.success": "Formuläret raderades framgångsrikt.",
    "message.deleteForm.error": "Fel vid radering av formuläret.",
    "message.duplicate.modal.title": "Vilket namn vill du spara din kopia med?",
    "message.duplicateForm.success": "Formuläret duplicerades framgångsrikt.",
    "message.duplicateForm.error": "Fel vid duplicering av formuläret.",
    "message.getMrk.error": "Fel vid hämtning av MRK",
    "message.getCulture.error": "Språket stöds inte",

    "messages.oops": "Hoppsan",
    "messages.link_copied": "Länken kopierad",
    "messages.confirm_delete":
        "Är du säker på att du vill radera {{formTitle}}? Den här åtgärden kan inte ångras.",
    "messages.confirm_delete_answers":
        "Detta formulär har redan svar. Om du fortsätter kommer alla svar att raderas och denna åtgärd kan inte ångras. Vill du fortsätta?",
    "messages.confirm_title_duplicate": "Det kommer att skickas till toppen av listan med namnet nedan:",
    "messages.required_fields": "Vänligen fyll i alla obligatoriska fält",
    "messages.error_support":
        "Ett problem uppstod vid utförandet av denna åtgärd. Om felet kvarstår, vänligen kontakta vår support.",
    "messages.error_field_id": "Vänligen kontrollera fält-ID!",
    "messages.error_field_no_options": "Vänligen kontrollera fältens alternativ!",
    "messages.error_field_preference_no_options": "Vänligen kontrollera fältets inställningar",
    "messages.error_qrbarcode_field_preference_no_options":
        "Du kan inte spara ett QR/Streckkodsfält utan att välja minst en typ",
    "messages.error_field_dropdown.title": "Du kan inte spara en rullgardinsmeny utan alternativ.",
    "messages.error_field_weight": "Vänligen kontrollera viktfälten!",
    "messages.error_all_field_weight": "Vänligen fyll i alla viktfält!",
    "messages.error_fields_repeated_id":
        "Fältet {{fieldIdA}} ID för sektionen {{sectionIdA}} är samma som fältet {{fieldIdB}} för sektionen {{sectionIdB}}.",
    "messages.error_section_repeated_id": "Det finns sektioner med samma Id!",
    "messages.error_section_name": "Vänligen kontrollera sektionsnamn!",
    "messages.error_section_id": "Vänligen kontrollera sektions-ID!",
    "messages.error_section_repeated_name": "Det finns sektioner med samma namn!",
    "messages.error_duplicate_section": "Du kan inte duplicera en sektion utan fält. Skapa en ny sektion istället.",
    "messages.error_mrk": "Ingen MRK hittades.",
    "messages.error_multiphotos_preferences": "Vänligen kontrollera flervalsinställningar!",
    "messages.error_invalid_preference": "Ogiltiga inställningsvärden",
    "messages.construction": "Denna sektion är under uppbyggnad för att ytterligare förbättra din upplevelse",
    "messages.languages_note":
        "Om du lämnar ett tomt fält kommer det att visas som standardspråk för användaren.",
    "messages.users_not_found": "Inga användare hittades",
    "messages.confirm_rules": "Vill du etablera regler för detta formulär?",
    "messages.empty_rule": "Om du har en villkorlig regel kan den skapas här",
    "messages.empty_section": "Välj ett fält för att lägga till det i sektionen",
    "messages.saved": "Ditt formulär har sparats framgångsrikt! Du kan nu kontrollera det under Mina formulär.",
    "messages.sign_out": "Vill du koppla från systemet?",
    "messages.cannot_change_form_status":
        "Du kan inte aktivera eller inaktivera detta formulär eftersom du är medägare.",
    "pages.my_forms": "Mina formulär",
    "pages.new_form": "Nytt formulär",
    "pages.feedback": "Feedback",
    "pages.builder": "Byggare",
    "pages.rules": "Regler",
    "pages.languages": "Språk",
    "pages.users": "Användare",
    "pages.usersAndCoOwners": "Användare och medägare",
    "my_forms.id": "ID",
    "my_forms.form_code": "Formulärkod",
    "my_forms.creation_date": "Skapelsedatum",
    "my_forms.updated_date": "Senaste ändringar",
    "my_forms.enable": "Aktivera",
    "my_forms.owner": "Ägare",
    "my_forms.actions": "Åtgärder",
    "actions.form": "Redigera formulär",
    "actions.rules": "Redigera regler",
    "actions.users": "Redigera användare",
    "actions.languages": "Redigera språk",
    "actions.power_bi": "Power BI-länk",
    "actions.export": "Exportera formulär",
    "actions.delete": "Radera formulär",
    "actions.duplicate": "Duplicera formulär",
    "new_form.import": "Importera nytt formulär",
    "new_form.creation_language": "Skapelsesspråk",
    "languages.portuguese": "Portugisiska",
    "languages.english": "Engelska",
    "languages.spanish": "Spanska",
    "languages.german": "Tyska",
    "languages.mandarin": "Mandarin",
    "languages.french": "Franska",
    "languages.turkish": "Turkiska",
    "languages.czech": "Tjeckiska",
    "languages.swedish": "Svenska",
    "languages.slovenian": "Slovenska",
    "languages.polish": "Polska",
    "languages.kosovo_albanian": "Albanian",
    "fields.text": "Kort text",
    "fields.textarea": "Lång text",
    "fields.number": "Numerisk",
    "fields.datetime": "Datum och tid",
    "fields.photo": "Foto",
    "fields.dropdown": "Rullgardinsmeny",
    "fields.single_option": "Enkelt val",
    "fields.customer": "Kund",
    "fields.equipment": "Utrustning",
    "fields.campaign": "Kampanj",
    "fields.end_reason": "Slutorsak",
    "fields.qr_code": "QR-/streckkod",
    "fields.signature_qr": "QR-signatur",
    "fields.boolean": "Enkelt val",
    "fields.dropdown-campaign": "Kampanj",
    "fields.dropdown-campaign-end-reason": "Slutorsak",
    "fields.dropdown-customer": "Kund",
    "fields.dropdown-equipment": "Utrustning",
    "fields.pictureFile": "Foto",
    "fields.qrbarcode": "QR-/streckkod",
    "fields.signature-qrcode": "QR-signatur",
    "fields.check_list": "Checklist",
    "constructor.fields": "Fält",
    "constructor.filter": "Filter",
    "constructor.by.equipment": "Filtrera efter utrustning",
    "constructor.by.customer": "Filtrera efter kund",
    "constructor.basic": "Grundläggande",
    "preferences.checklistValue": "Max markerade alternativ",
    "constructor.rhim": "RHIM-integration",
    "constructor.section_title": "Sektionstitel",
    "constructor.unsaves_changes": "Olagrade ändringar",
    "constructor.mrk": "Utrustning MRK",
    "constructor.preferences": "Inställningar",
    "preferences.placeholder": "Välj ett fält för att redigera dess inställningar",
    "preferences.required": "Obligatorisk",
    "preferences.score": "Poäng",
    "preferences.options": "Alternativ",
    "preferences.automatic": "Automatisk",
    "preferences.automatic_id": "Automatiskt ID",
    "preferences.field_id": "Fält-ID",
    "preferences.field_weight": "Fälttypning",
    "preferences.section_weight": "Sektionstypning",
    "preferences.section_id": "Sektionens ID",
    "preferences.options_placeholder": "Skriv in föremål, separerade med enter",
    "preferences.mrk_placeholder": "Välj ett MRK",
    "preferences.max": "Maximalt",
    "preferences.min": "Minimalt",
    "preferences.formDescription": "Använd som formulärbeskrivning",
    "preferences.error_empty_qrcode": "Välj minst ett alternativ",
    "preferences.typeQrOrBarCode": "Typ",
    "preferences.IntegerOrFloat": "Typ",
    "preferences.decimalPlacesMin": "Decimaler min",
    "preferences.DecimalPlaces": "Decimaler",
    "add_options.option": "Alternativ",
    "add_options.weight": "Vikt",
    "add_options.response": "Svar",
    "add_options.add_na": "Lägg till N/A",
    "construction.go_back": "Gå tillbaka",
    "languages.notes": "Anteckningar",
    "users.email": "Användares e-post",
    "coOwners.email": "Medskribenters e-post",
    "users.multiple_association": "Flera associeringar (fyll med en lista över e-postadresser, separerade med semikolon)",
    "users.finish": "Avsluta formulär",
    "globalization.title": "Skriv din formulärtitel på",
    "globalization.section": "Skriv din sektionstitel på",
    "globalization.field": "Skriv ditt fältnamn på",
    "globalization.field.description": "Skriv din fälts beskrivning på",
    "globalization.option": "Skriv ditt alternativvärde på",
    "rules.if": "Om",
    "rules.status": "Status",
    "rules.response": "Svar",
    "rules.action": "Åtgärd",
    "rules.question": "Fråga",
    "rules.options": "Alternativ",
    "option.is": "Är",
    "option.isn't": "Är inte",
    "option.hide.section": "Dölj sektion",
    "option.hide.field": "Dölj fält",
    "option.show.section": "Visa sektion",
    "option.show.field": "Visa fält",
    "option.filter": "Filter",
    "option.required": "Obligatorisk",
    "pagination.view": "Visar {{from}}-{{to}} av {{count}}",
    "pagination.rows_per_page": "Rader per sida:",
    "messages.error_form_access_denied": "Du har inte tillgång till detta formulär. Sök efter ett formulär där du är ägare eller medägare",
    "messages.page_not_found": "Sidan hittades inte",
    "messages.text_page_not_found": "Tyvärr, sidan du försöker nå finns inte.",
    "messages.go_back_my_forms": "Gå tillbaka till Mina formulär",
    "messages.access_denied": "Åtkomst nekad",
    "messages.text_access_denied": "Du har inte åtkomst till detta formulär. Vänligen sök efter ett annat formulär där du är författare eller medförfattare.",
    "messages.form_not_found": "Formulär hittades inte",
    "messages.text_form_not_found": "Tyvärr, formuläret du försöker nå finns inte. Sök efter ett annat formulär eller skapa ett nytt.",
    "preferences.description": "Beskrivning",
    "messages.fieldsInformDescriptionPreference": "Du har redan valt {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fält",
    "messages.error_single_option_no_options": "Du kan inte spara ett enkelt val fält utan alternativ.",
    "messages.max_filled_as_form_description": "För att välja detta fält, avmarkera ett annat fält",
    "preferences.option_integer": "Heltal",
    "preferences.option_decimal": "Decimal",
    "messages.tooltip_decimal_minimum_alert": "På grund av de två decimalerna är minsta teckenlängden för decimalfält tre.",
    "message.form_last_update": "Senaste uppdatering",
    "message.form_last_update_by": "Av"
};
