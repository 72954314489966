import { getAllSectionIds, getUniqueId } from "../../../helpers/util";

import { ItemPreference } from "../../../repositories/interfaces";
import { Section } from "../../../models/Section/Section";
import { v4 as uuidv4 } from "uuid";

export const createDuplicateSection = (
  originalSection: Section,
  items: Section[],
  translatedItems: any[]
): Section => {
  const sectionIndex = translatedItems?.findIndex(
    (section: any) => section.uuid === originalSection.uuid
  );

  const newSectionTitle = `Copy of ${translatedItems[sectionIndex].defaultLanguageTitle}`;

  const allSectionIds = getAllSectionIds(items);

  const newSectionId = getUniqueId("", newSectionTitle, allSectionIds);
  const newFormSectionLocales = originalSection.formSectionLocales.map((locale: any) => {
    return {
      ...locale,
      title: newSectionTitle
    };
  });
  const newSection: Section = {
    id: 0,
    title: newSectionTitle,
    index: items.length, // update index to reflect new position
    uuid: uuidv4(),
    score: originalSection.score,
    sectionId: newSectionId,
    formSectionLocales: newFormSectionLocales
  };

  return newSection;
};

export const getUniqueFieldId = (baseFieldId: string, existingFieldIds: string[]): string => {
  let newFieldId = baseFieldId + "_copy";
  let counter = 1;

  while (existingFieldIds.includes(newFieldId)) {
    newFieldId = baseFieldId + "_copy_" + counter; //test more cases
    counter++;
  }

  return newFieldId;
};

export const deepCopyField = (originalField: any, newFieldId: any, newSection: any): any => {
  const newField = {
    ...originalField,
    id: 0,
    formSectionid: 0,
    sectionId: newSection.id,
    uuid: uuidv4(),
    title: originalField.title,
    name: originalField.name,
    formFieldLocales: originalField.formFieldLocales.map((locale: any) => ({
      ...locale,
      title: locale.title,
      id: 0
    })),
    fieldListUuid: newSection.uuid,
    fieldId: newFieldId,
    preferences: originalField.preferences.map((pref: ItemPreference) => ({
      ...pref,
      value: typeof pref.value === 'object' 
        ? JSON.parse(JSON.stringify(pref.value))
        : pref.value
    }))
  };
  if (newField.options && Array.isArray(newField.options)) {
    newField.options = newField.options.map((option: any) => ({
      ...option,
      id: 0,
      fieldId: newField.id,
      formFieldId: newField.id,
      formOptionLocales: option.formOptionLocales.map((locale: any) => ({
        ...locale,
        id: 0,
        formOptionId: 0
      }))
    }));
    newField.fieldOptions = [...newField.options];

    // Fix the preferences
    if (Array.isArray(newField.preferences)) {
      const optionsString = JSON.stringify(newField.options);

      // Find and update each option-related preference
      newField.preferences = newField.preferences.map((pref: { key: string; }) => {
        switch (pref.key) {
          case "Field-options":
            return { ...pref, value: optionsString };
          case "Fieldoptions":
            return { ...pref, value: newField.options };
          case "FieldOptions":
            return { ...pref, value: newField.options };
          case "ListItemsDropdown":
            return { ...pref, value: optionsString };
          case "ListItemsRadio":
            return { ...pref, value: optionsString };
          default:
            return pref;
        }
      });
    }
  }
  return newField;
}