export default {
  "common.cancel": "Zrušit",
  "common.delete": "Smazat",
  "common.duplicate": "Duplikovat",
  "common.title": "Název",
  "common.upload": "Nahrát",
  "common.create": "Vytvořit",
  "common.file": "Soubor",
  "common.section": "Sekce",
  "common.add": "Přidat",
  "common.continue": "Pokračovat",
  "common.confirm": "Potvrdit",
  "common.save": "Uložit",
  "common.saved": "Uloženo",
  "common.yes": "Ano",
  "common.no": "Ne",
  "common.skip": "Přeskočit",
  "common.ok": "Ok",
  "common.success": "Úspěch",
  "common.sign_out": "Odhlásit se",
  "common.answer": "Odpověď",
  "common.rule": "Pravidlo",
  
  "message.createAnswer.success": "Odpověď byla úspěšně uložena!",
  "message.createAnswer.error.answer": "Chyba při vytváření odpovědi",
  "message.createAnswer.error.image": "Chyba při vytváření obrázku",
  "message.createAnswer.error.image.type": "Neplatný typ obrázku",
  "message.getAnswers.error.invalidInput": "Zadejte maximální období {{maxDays}} dnů pro filtrování odpovědí.",
  "message.getAnswers.error.answer": "Chyba při zpracování AnswerData",
  "message.getAnswers.error.image": "Chyba při zpracování obrázků",
  "message.formValidation.missingTitle": "Vyplňte pole Název",
  "message.formValidation.missingEmail": "Vyplňte pole E-mail",
  "message.formValidation.missingSectionTitle": "Vyplňte název všech sekcí",
  "message.formValidation.missingFieldTitle": "Vyplňte názvy všech polí",
  "message.formValidation.missingFieldType": "Vyberte typ pro všechna pole",
  "message.formValidation.missingFieldId": "Zadejte ID pro všechna pole.",
  "message.formValidation.missingEquipmentMrk": "Vyberte vybavení MRK v poli vybavení",
  "message.formValidation.missingSection": "Formulář potřebuje alespoň jednu sekci, aby správně fungoval",
  "message.formValidation.multipleCustomerField": "Formulář může obsahovat pouze jedno pole SAR Customer",
  "message.formValidation.multipleEquipmentInSection": "Formulář může obsahovat pouze jedno pole SAR Equipment na sekci",
  "message.formValidation.missingCustomerField": "Pro vytvoření formuláře s SAR Equipment nejprve vložte pole typu SAR Customer",
  "message.formValidation.missingEquipmentField": "Pro vytvoření formuláře s SAR Kampaní/Důvodem ukončení nejprve vložte pole typu SAR Equipment",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment": "SAR Customer musí být zadaný před SAR Equipment",
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign": "SAR Customer musí být zadaný před SAR Kampaní",
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason": "SAR Customer musí být zadaný před SAR Důvodem ukončení",
  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign": "SAR Equipment musí být zadaný před SAR Kampaní",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason": "SAR Equipment musí být zadaný před SAR Důvodem ukončení",
  "message.formValidation.wrongEquipmentFieldPosition": "SAR Equipment musí být umístěný před SAR Kampaní",
  "message.formValidation.failureValidatingRules": "Chyba při validaci pravidel formuláře.",
  "message.formValidation.missingTargetOptionsInRules": "Chybějící možnosti otázek v pravidlech formuláře.",
  "message.createForm.success": "Formulář byl úspěšně uložen!",
  "message.createForm.error": "Chyba při ukládání formuláře. Zkuste to znovu.",
  "message.updateForm.success": "Formulář byl úspěšně uložen.",
  "message.updateForm.error": "Chyba při ukládání formuláře",
  "message.toggleFormEnable.success.enable": "Formulář úspěšně povolen!",
  "message.toggleFormEnable.success.disable": "Formulář byl úspěšně deaktivován!",
"message.toggleFormEnable.error": "Chyba při aktivaci/deaktivaci formuláře",
"message.hasAnswers": "Formulář má odpovědi.",
"message.hasNoAnswers": "Formulář nemá žádné odpovědi.",
"message.hasAnswers.error": "Chyba při kontrole, zda má formulář odpovědi",
"message.deleteForm.success": "Formulář byl úspěšně smazán.",
"message.deleteForm.error": "Chyba při mazání formuláře.",
"message.duplicate.modal.title": "Pod jakým názvem chcete uložit kopii?",
"message.duplicateForm.success": "Formulář byl úspěšně duplikován.",
"message.duplicateForm.error": "Chyba při duplikování formuláře.",
"message.getMrk.error": "Chyba při získávání MRK",
"message.getCulture.error": "Nepodporovaný jazyk",

"messages.oops": "Oops",
"messages.link_copied": "Odkaz zkopírován",
"messages.confirm_delete": "Opravdu chcete smazat {{formTitle}}? Tuto akci nelze vrátit zpět.",
"messages.confirm_delete_answers": "Tento formulář již má odpovědi. Pokračováním budou všechny odpovědi smazány a tuto akci nelze vrátit zpět. Chcete pokračovat?",
"messages.confirm_title_duplicate": "Bude odesláno na začátek seznamu pod níže uvedeným názvem:",
"messages.required_fields": "Vyplňte prosím všechna povinná pole",
"messages.error_support": "Při provádění této akce došlo k problému. Pokud chyba přetrvává, kontaktujte prosím naši podporu.",
"messages.error_field_id": "Zkontrolujte prosím ID pole!",
"messages.error_field_no_options": "Zkontrolujte prosím možnosti polí!",
"messages.error_field_preference_no_options": "Zkontrolujte prosím preference pole",
"messages.error_qrbarcode_field_preference_no_options": "Nemůžete uložit pole QR/čárového kódu bez výběru alespoň jednoho typu",
"messages.error_field_dropdown.title": "Nemůžete uložit rozbalovací nabídku bez možností.",
"messages.error_field_weight": "Zkontrolujte prosím váhy polí!",
"messages.error_all_field_weight": "Vyplňte prosím všechna pole s váhami!",
"messages.error_fields_repeated_id": "ID pole {{fieldIdA}} v sekci {{sectionIdA}} je stejné jako ID pole {{fieldIdB}} v sekci {{sectionIdB}}.",
"messages.error_section_repeated_id": "Existují sekce se stejným ID!",
"messages.error_section_name": "Zkontrolujte prosím název sekce!",
"messages.error_section_id": "Zkontrolujte prosím ID sekce!",
"messages.error_section_repeated_name": "Existují sekce se stejným názvem!",
"messages.error_duplicate_section": "Nelze duplikovat sekci bez polí. Vytvořte místo toho novou sekci.",
"messages.error_mrk": "Žádný MRK nebyl nalezen.",
"messages.error_multiphotos_preferences": "Zkontrolujte prosím preference pro více fotek!",
"messages.error_invalid_preference": "Neplatné hodnoty preferencí",
"messages.construction": "Tato sekce je ve výstavbě, aby dále zlepšila váš zážitek",
"messages.languages_note": "Pokud necháte pole prázdné, bude zobrazeno jako výchozí jazyk pro uživatele.",
"messages.users_not_found": "Žádní uživatelé nebyli nalezeni",
"messages.confirm_rules": "Chcete stanovit pravidla pro tento formulář?",
"messages.empty_rule": "Pokud máte podmíněné pravidlo, lze jej vytvořit zde",
"messages.empty_section": "Vyberte pole pro přidání do sekce",
"messages.saved": "Váš formulář byl úspěšně uložen! Nyní si jej můžete prohlédnout v sekci Moje formuláře.",
"messages.sign_out": "Chcete se odpojit od systému?",
"messages.cannot_change_form_status": "Nemůžete aktivovat nebo deaktivovat tento formulář, protože jste spoluvlastník.",
  "pages.my_forms": "Moje formuláře",
"pages.new_form": "Nový formulář",
"pages.feedback": "Zpětná vazba",
"pages.builder": "Stavitel",
"pages.rules": "Pravidla",
"pages.languages": "Jazyky",
"pages.users": "Uživatelé",
"pages.usersAndCoOwners": "Uživatelé a spoluvlastníci",
"my_forms.id": "ID",
"my_forms.form_code": "Kód formuláře",
"my_forms.creation_date": "Datum vytvoření",
"my_forms.updated_date": "Poslední změny",
"my_forms.enable": "Povolit",
"my_forms.owner": "Vlastník",
"my_forms.actions": "Akce",
"actions.form": "Upravit formulář",
"actions.rules": "Upravit pravidla",
"actions.users": "Upravit uživatele",
"actions.languages": "Upravit jazyky",
"actions.power_bi": "Odkaz na Power BI",
"actions.export": "Exportovat formulář",
"actions.delete": "Smazat formulář",
"actions.duplicate": "Duplikovat formulář",
"new_form.import": "Importovat nový formulář",
"new_form.creation_language": "Jazyk vytvoření",
"languages.portuguese": "Portugalština",
"languages.english": "Angličtina",
"languages.spanish": "Španělština",
"languages.german": "Němčina",
"languages.mandarin": "Mandarínština",
"languages.french": "Francouzština",
"languages.turkish": "Turečtina",
"languages.czech": "Čeština",
"languages.polish": "Polština",
"languages.slovenian": "Slovinština",
"languages.swedish": "Švédština",
"languages.kosovo_albanian": "Albánština",
"fields.text": "Krátký text",
"fields.textarea": "Dlouhý text",
"fields.number": "Číselný",
"fields.datetime": "Datum a čas",
"fields.photo": "Fotografie",
"fields.dropdown": "Rozbalovací nabídka",
"fields.single_option": "Jednotlivá možnost",
"fields.customer": "Zákazník",
"fields.equipment": "Zařízení",
"fields.campaign": "Kampaň",
"fields.end_reason": "Důvod ukončení",
"fields.qr_code": "QR/Čárový kód",
"fields.signature_qr": "Podpis QR",
"fields.boolean": "Jednotlivá možnost",
"fields.dropdown-campaign": "Kampaň",
"fields.dropdown-campaign-end-reason": "Důvod ukončení",
"fields.dropdown-customer": "Zákazník",
"fields.dropdown-equipment": "Zařízení",
"fields.pictureFile": "Fotografie",
"fields.qrbarcode": "QR/Čárový kód",
"fields.signature-qrcode": "Podpis QR",
"fields.check_list": "Checklist",
"constructor.fields": "Pole",
"constructor.filter": "Filtr",
"constructor.by.equipment": "Filtrovat podle zařízení",
"constructor.by.customer": "Filtrovat podle zákazníka",
"constructor.basic": "Základní",
"preferences.checklistValue": "Maximální počet zkontrolovaných možností",
"constructor.rhim": "Integrace RHIM",
"constructor.section_title": "Název sekce",
"constructor.unsaves_changes": "Neuložené změny",
"constructor.mrk": "Zařízení MRK",
"constructor.preferences": "Předvolby",
"preferences.placeholder": "Vyberte pole pro úpravu jeho předvoleb",
"preferences.required": "Povinné",
"preferences.score": "Skóre",
"preferences.options": "Možnosti",
"preferences.automatic": "Automatické",
"preferences.automatic_id": "Automatické ID",
"preferences.field_id": "ID pole",
"preferences.field_weight": "Váha pole",
"preferences.section_weight": "Váha sekce",
"preferences.section_id": "ID sekce",
"preferences.options_placeholder": "Zadejte položky oddělené stisknutím enter",
"preferences.mrk_placeholder": "Vyberte MRK",
"preferences.max": "Maximum",
"preferences.min": "Minimum",
"preferences.formDescription": "Použít jako popis formuláře",
"preferences.error_empty_qrcode": "Vyberte alespoň jednu možnost",
"preferences.typeQrOrBarCode": "Typ",
"preferences.IntegerOrFloat": "Typ",
"preferences.decimalPlacesMin" : "Minimální počet desetinných míst",
"preferences.DecimalPlaces": "Desetinná místa",
"add_options.option": "Možnost",
"add_options.weight": "Váha",
"add_options.response": "Odpověď",
"add_options.add_na": "Přidat N/A",
"construction.go_back": "Zpět",
"languages.notes": "Poznámky",
"users.email": "E-mail uživatelů",
"coOwners.email": "E-mail spoluvlastníků",
"users.multiple_association": "Vícenásobné přiřazení (vyplňte ho seznamem e-mailů oddělených středníkem)",
"users.finish": "Dokončit formulář",

"globalization.title": "Napište název svého formuláře v",
"globalization.section": "Napište název své sekce v",
"globalization.field": "Napište název svého pole v",
"globalization.field.description": "Napište popis svého pole v",
"globalization.option": "Napište hodnotu své možnosti v",

"rules.if": "Pokud",
"rules.status": "Stav",
"rules.response": "Odpověď",
"rules.action": "Akce",
"rules.question": "Otázka",
"rules.options": "Možnosti",

"option.is": "Je",
"option.isn't": "Není",
"option.hide.section": "Skrýt sekci",
"option.hide.field": "Skrýt pole",
"option.show.section": "Zobrazit sekci",
"option.show.field": "Zobrazit pole",
"option.filter": "Filtr",
"option.required": "Povinné",

"pagination.view": "Zobrazení {{from}}-{{to}} z {{count}}",
"pagination.rows_per_page": "Řádků na stránku:",
"messages.error_form_access_denied": "Nemáte přístup k tomuto formuláři. Vyhledejte formulář, kde jste vlastník nebo spoluvlastník",
"messages.page_not_found": "Stránka nebyla nalezena",
"messages.text_page_not_found": "Omlouváme se, stránka, kterou se pokoušíte přistupovat, neexistuje.",
"messages.go_back_my_forms": "Zpět na Moje formuláře",
"messages.access_denied": "Přístup zamítnut",
"messages.text_access_denied": "Nemáte přístup k tomuto formuláři. Vyhledejte jiný formulář, kde jste autor nebo spoluautor.",
"messages.form_not_found": "Formulář nebyl nalezen",
"messages.text_form_not_found": "Omlouváme se, formulář, ke kterému se pokoušíte přistupovat, neexistuje. Vyhledejte jiný formulář nebo vytvořte nový.",
"preferences.description": "Popis",
"messages.fieldsInformDescriptionPreference": "Vybrali jste již {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} polí",
"messages.error_single_option_no_options": "Nemůžete uložit pole s jedinou možností bez možností.",
"messages.max_filled_as_form_description": "Chcete-li vybrat toto pole, zrušte výběr jiného pole",

"preferences.option_integer": "Celé číslo",
"preferences.option_decimal": "Desetinné číslo",
"messages.tooltip_decimal_minimum_alert": "Kvůli dvěma desetinným místům je minimální délka pole pro desetinné číslo 3 znaky.",
"message.form_last_update": "Poslední aktualizace",
"message.form_last_update_by": "Od",
};
