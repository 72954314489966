import { FilterActionTypes, StateMapper, action, thunk, thunkOn } from "easy-peasy";
import { SectionModelInterface, SectionTranslation } from "./sectionModelInterface";
import {
  addSectionLocalesToNewSection,
  getAllSectionIds,
  getUniqueId
} from "../../../helpers/util";
import { createDuplicateSection, deepCopyField, getUniqueFieldId } from "./duplicateSectionUtils";

import { FormRule } from "../../../feature/rules/interface/rulesInterface";
import { ModalType } from "../../../feature/modal/enum/ModalType";
import { Section } from "../../../models/Section/Section";

const sectionModel: SectionModelInterface = {
  items: [],
  translatedItems: [],
  selectedSection: null,
  setItems: action((state, payload) => {
    state.items = payload.map((item) => item);
    state.translatedItems = payload.map((item) => {
      return {
        ...item,
        index: item.index,
        defaultLanguageTitle: item.title,
        uuid: item.uuid
      };
    });
  }),
  add: thunk((actions, payload, { getStoreState }) => {
    const { items } = getStoreState().sections;
    const { form, languages } = getStoreState();
    const { section, t } = payload;
    const allSectionIds = getAllSectionIds(items);
    const sectionTitle = section.title.includes("constructor.") ? t(section.title) : section.title;
    const sectionId = getUniqueId("", sectionTitle, allSectionIds);
    const sectionItemAdd = { ...section, sectionId };
    addSectionLocalesToNewSection(
      sectionItemAdd,
      form.formLanguageList,
      languages.formDefaultLanguage,
      t
    );

    items.push(sectionItemAdd);
    actions.setItems(items);
  }),
  remove: thunk((actions, payload, { getState, getStoreActions }) => {
    const { items, translatedItems } = getState();
    const { fields } = getStoreActions();
    const sectionIndex = translatedItems?.findIndex(
      (section: any) => section.uuid === payload.uuid
    );
    translatedItems.splice(sectionIndex, 1);
    items.splice(payload.index, 1);
    if (items.length) {
      const newSectionIndex = payload.index === items.length ? payload.index - 1 : payload.index;
      items[newSectionIndex].index = newSectionIndex;
      actions.selectSection(items[newSectionIndex]);
    }
    actions.update(items);
    fields.removeList(payload.uuid);
  }),

  selectSection: action((state, payload) => {
    state.selectedSection = payload;
  }),
  update: action((state, payload) => {
    state.items = [...payload];
  }),
  updateSection: action((state, payload) => {
    state.selectedSection = payload;
    state.items[payload.index] = state.selectedSection;
    const sectionIndex = state.translatedItems?.findIndex(
      (section: any) => section.uuid === payload.uuid
    );
    const selectedSection = state.translatedItems[sectionIndex];
    selectedSection.defaultLanguageTitle = payload.title;
    selectedSection.uuid = payload.uuid;
    selectedSection.sectionId = payload.sectionId;
    Object.keys(selectedSection).forEach((key) => {
      if (key.includes("title-") && selectedSection.score === payload.score)
        selectedSection[key] = "";
    });
    selectedSection.score = payload.score;
    selectedSection.weight = payload.weight;
  }),
  moveSectionUp: action((state, payload) => {
    const index = payload;
    if (index > 0) {
      const sectionsList = state.items.slice();
      const sectionsTranslatedList = state.translatedItems.slice();
      const indexAbove = payload - 1;
      const sectionTranslated = sectionsTranslatedList[index];
      sectionsTranslatedList[index] = sectionsTranslatedList[indexAbove];
      sectionsTranslatedList[indexAbove] = sectionTranslated;
      const section = sectionsList[index];
      sectionsList[index] = sectionsList[indexAbove];
      sectionsList[indexAbove] = section;
      state.items = sectionsList;
      state.translatedItems = sectionsTranslatedList;
    }
  }),
  moveSectionDown: action((state, payload) => {
    const index = payload;
    if (index < state.items.length - 1) {
      const sectionsList = state.items.slice();
      const sectionsTranslatedList = state.translatedItems.slice();
      const indexBelow = payload + 1;
      const sectionTranslated = sectionsTranslatedList[index];
      sectionsTranslatedList[index] = sectionsTranslatedList[indexBelow];
      sectionsTranslatedList[indexBelow] = sectionTranslated;
      const section = sectionsList[index];
      sectionsList[index] = sectionsList[indexBelow];
      sectionsList[indexBelow] = section;
      state.items = sectionsList;
      state.translatedItems = sectionsTranslatedList;
    }
  }),
  duplicateSection: thunk(
    (actions, payload: SectionTranslation, { getState, getStoreState, getStoreActions }) => {
      const { section: sectionToDuplicate, t } = payload;
      const { items, translatedItems } = getState();
      const fieldActions = getStoreActions().fields;
      const formRules = getStoreState().rules.rules;
      const sectionActions = getStoreActions().sections;
      const ruleActions = getStoreActions().rules;
      const notification = getStoreActions().notification;
      try {
        const newSection = createDuplicateSection(sectionToDuplicate, items, translatedItems);

        const fields = getStoreState().fields.items;
        const fieldsToDuplicate = fields[sectionToDuplicate.uuid];
        if (fieldsToDuplicate === undefined) {
          throw new Error(t("messages.error_duplicate_section"));
        }
        sectionActions.add({ section: newSection, t: t });

        const getAllFieldIds = () => {
          const allFields: string[] = [];
          Object.values(fields).forEach((sectionFields) => {
            sectionFields.forEach((field) => {
              allFields.push(field.fieldId);
            });
          });
          return allFields;
        };

        const existingFieldIds = getAllFieldIds();
        const fieldIdMapping: { [key: string]: string } = {};

        fieldsToDuplicate.forEach((field: any) => {
          const newFieldId = getUniqueFieldId(field.fieldId, existingFieldIds);
          fieldIdMapping[field.fieldId] = newFieldId;
          const newField = deepCopyField(field, newFieldId, newSection);
          existingFieldIds.push(newFieldId);

          fieldActions.addField({ field: newField, section: newSection, t: () => "placeholder" });
        });

        formRules.forEach((rule: FormRule) => {
          if (rule.agent && fieldIdMapping[rule.agent]) {
            const newRule: FormRule = {
              ...rule,
              id: 0,
              name: rule.name + " (copy)",
              agent: fieldIdMapping[rule.agent],
              target: rule.target,
              orderIndex: rule.orderIndex + 1,
              formId: rule.formId,
              targetOptions: rule.targetOptions
            };

            if (rule.target && fieldIdMapping[rule.target]) {
              newRule.target = fieldIdMapping[rule.target];
            }

            if (rule.targetOptions) {
              try {
                const targetOptions =
                  typeof rule.targetOptions === "string"
                    ? JSON.parse(rule.targetOptions)
                    : rule.targetOptions;

                newRule.targetOptions = JSON.stringify({
                  ...targetOptions,
                  value: fieldIdMapping[targetOptions.value] || targetOptions.value
                });
              } catch {
                newRule.targetOptions = rule.targetOptions;
              }
            }

            ruleActions.addRule(newRule);
          }
        });
      } catch (error: any) {
        notification.setProperties({
          confirmText: t("common.ok").toUpperCase(),
          message: error.message,
          title: t("messages.oops"),
          type: ModalType.Error,
          visible: true
        });
      }
      const allRules = getStoreState().rules.rules;
      ruleActions.setRulesOrderIndexes(allRules);
    }
  ),
  updateSectionTranslation: action((state, payload) => {
    const { type, uuid } = payload;
    const language = payload.language || {};
    const text = payload.text || "";

    if (state.translatedItems) {
      switch (type) {
        case "ADD":
          addTranslation(state, language);
          break;
        case "UPDATE":
          const sectionToUpdate = state.items.find((section) => section.uuid === uuid);
          updateTranslation(state, { language, text }, sectionToUpdate);
          break;
        case "REMOVE":
          removeTranslation(state, language);
          break;
        default:
          break;
      }
    }
  }),
  onFormClear: thunkOn(
    (actions, storeActions) => [storeActions.form.clearFormData],
    (actions, target, { getState }) => {
      const state = getState();
      state.items = [];
    }
  )
};

const addTranslation = (
  state: StateMapper<FilterActionTypes<SectionModelInterface>>,
  language: { id: any; isoCode: any }
) => {
  state.items.forEach((section) => {
    let existingSectionLocale = section.formSectionLocales.find(
      (locale) => locale.cultureId === language.id
    );
    if (!existingSectionLocale) {
      section.formSectionLocales.push({
        cultureId: language.id,
        formSectionId: section.id,
        title: ""
      });
    }
  });
  state.translatedItems.forEach((item) => {
    if (!item[`title-${language.isoCode}`]) item[`title-${language.isoCode}`] = "";
  });
};
const updateTranslation = (
  state: StateMapper<FilterActionTypes<SectionModelInterface>>,
  payload: { language: any; text: any },
  sectionToUpdate: Section | undefined
) => {
  const { language, text } = payload;
  let localeToUpdate = sectionToUpdate?.formSectionLocales.find(
    (locale) => locale.cultureId === language.id
  );
  if (localeToUpdate) localeToUpdate.title = text;
  const newItems: Section[] = state.items.map((item) => {
    if (item.uuid === sectionToUpdate?.uuid) {
      item.formSectionLocales.forEach((locale) => {
        if (locale.cultureId === localeToUpdate?.cultureId) {
          locale.title = localeToUpdate?.title;
        }
      });
      item[`title-${language.isoCode}`] = text;
    }
    return item;
  });
  state.items = newItems;
  state.translatedItems.forEach((item) => {
    if (item.uuid === sectionToUpdate?.uuid) {
      item[`title-${language.isoCode}`] = text;
    }
  });
};

const removeTranslation = (
  state: StateMapper<FilterActionTypes<SectionModelInterface>>,
  language: { id: any; isoCode: any }
) => {
  state.items.forEach((section: { formSectionLocales: any[] }) => {
    section.formSectionLocales = section.formSectionLocales.filter((locale: { cultureId: any }) => {
      return locale.cultureId !== language.id;
    });
  });
  state.translatedItems.forEach((item: { [x: string]: any }) => {
    delete item[`title-${language.isoCode}`];
  });
};

export default sectionModel;
